<template>
    <div class="w-full max-w-[1340px] m-auto mb:px-4 lg:px-4">
        <div class="rounded-lg bg-brown-100">
            <h2 class="font-title tracking-tight leading-tight text-title-mob sm:text-title-desk font-bold mb-5 last:mb-0 pt-10 sm:pt-10 lg:pt-10 text-center">
                Ranking
            </h2>
            <div v-if="activeClub" class="max-w-[1280px] m-auto grid gap-y-4 grid-cols-1 py-10 md:py-15" :class="{
                'sm:grid-cols-2 ': !activeClub.categories.length,
                'sm:grid-cols-2 lg:grid-cols-4': activeClub.categories.length
            }">
                <div
                    v-for="category in activeClub.categories"
                    class="w-full text-center border-r border-black/10"
                >
                    <h3 class="text-[24px] md:text-[48px] font-title bold">{{ activeClub.rankings.city.category }} / {{ activeClub.rankings.city.category_total }}</h3>
                    <p class="uppercase text-[16px] font-title">{{ category.name }}</p>
                    <p class="uppercase text-[16px] font-title">{{ activeClub.city.name }}</p>
                </div>
                <div class="w-full text-center border-r border-black/10">
                    <h3 class="text-[24px] md:text-[48px] font-title bold">{{ activeClub.rankings.city.overall }} / {{ activeClub.rankings.city.overall_total }}</h3>
                    <p class="uppercase text-[16px] font-title">The Fit Guide</p>
                    <p class="uppercase text-[16px] font-title">{{ activeClub.city.name }}</p>
                </div>
                <div
                    v-for="category in activeClub.categories"
                    class="w-full text-center border-r border-black/10"
                >
                    <h3 class="text-[24px] md:text-[48px] font-title bold">{{ activeClub.rankings.global.category }} / {{ activeClub.rankings.global.category_total }}</h3>
                    <p class="uppercase text-[16px] font-title">{{ category.name }}</p>
                    <p class="uppercase text-[16px] font-title">Worldwide</p>
                </div>
                <div class="w-full text-center ">
                    <h3 class="text-[24px] md:text-[48px] font-title bold">{{ activeClub.rankings.global.overall }} / {{ activeClub.rankings.global.overall_total }}</h3>
                    <p class="uppercase text-[16px] font-title">The Fit Guide</p>
                    <p class="uppercase text-[16px] font-title">Worldwide</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        activeClub: {
            type: Object
        }
    }
}
</script>
