<template>
    <div class="w-full flex justify-center flex-col items-center max-w-[1420px] m-auto px-5 mb-10">
        <div v-if="featured && title !== ''" class="flex flex-col justify-center items-center w-full mb-5">
            <h3 class="text-center uppercase mb-[20px] tracking-[3.5px]">{{ title }}</h3>
        </div>
        <div v-if="featured" class="mb-10 px-5">
            <featured-article v-if="featuredArticle" :article="featuredArticle"></featured-article>
        </div>
        <div class="flex flex-col justify-center items-center w-full">
            <h3 class="text-center uppercase mb-[20px] tracking-[3.5px]">{{ title }}</h3>
            <article-filters
                :concept.sync="concept"
                :keyword.sync="keyword"
            ></article-filters>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-5">
            <article-card
                v-for="article in articles"
                :key="article.id"
                :featured_article_id="featuredArticle?.id"
                :article="article"
            >
            </article-card>
        </div>
        <article-skeleton v-if="loading_posts" />

        <a
            v-if="load_type === 'viewall'"
            href="/news"
            class="button max-w-fit mt-10">
            <span>
                View All Articles
            </span>
        </a>
        <button
            v-else-if="load_type === 'loadMore' && !loading_posts && !last_page"
            type="button"
            @click="loadMore"
            class="button max-w-fit mt-10"
        >
            <span>
                Load More
            </span>
        </button>
    </div>
</template>

<script>
  import ArticleCard from './ArticleCard.vue';
  import FeaturedArticle from './FeaturedArticle.vue';
  import ArticleFilters from './ArticleFilters.vue';
  import ArticleSkeleton from './ArticleSkeleton.vue';

  export default {
    components: {
        ArticleCard,
        FeaturedArticle,
        ArticleFilters,
        ArticleSkeleton,
    },
    props: {
        featured: {
            required: false,
            default: false
        },
        city: {
            type: Object|null,
            required: false,
            default: false,
        },
        category: {
            type: String,
            required: false,
        },
        initialConcept: {
          type: String,
          required: false,
          default: null
        },
        limit: {
            type: Number,
            required: false
        },
        load_type: {
            type: String,
            default: 'loadMore'
        },
        postsUrl: {
            type: String,
            default: '/api/articles',
        },
        title: { 
            type: String,
            default: '',
            required: false,
        },
        preloadedArticles: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data () {
        return {
            featuredArticle: null,
            articles: this.preloadedArticles,
            concept: null,
            loading_posts: false,
            error: null,
            page: 2,
            total_posts: null,
            keyword: null,
            last_page: false,
        }
    },
    mounted() {
        if (this.articles.length === 0) {
            this.getPosts();
        }
    },
    computed: {
      requestParams() {
        const params = {
          page: this.page
        }

        if (this.limit) {
            params.per_page = this.limit;
        }

        if (this.category) {
            params.category = this.category.slug;
        }

        if (this.city) {
            params.city = this.city;
        }

        if (this.concept) {
            params.category = this.concept;
        }

        if (this.keyword) {
            params.keyword = this.keyword;
        }

        return params;
      },
    },
    methods: {
        getPosts() {
            this.loading_posts = true;

            this.error = null;

            const params = this.requestParams;

            fetch(this.postsUrl + '?' + new URLSearchParams(Object.entries(params)), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => response.json())
            .then((response) => {
                this.articles.push(...response.data);
                this.page++;
                this.last_page = response.meta.last_page < this.page;
            })
            .catch((error) => {
                this.error = 'Error getting posts.';
            })
            .then(() => {
                this.loading_posts = false;
            });
        },

        async loadMore () {
            this.getPosts();
        }
    },
    watch: {
        concept(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.page = 1;
                this.articles = [];
                this.getPosts();
            }
        },
        keyword(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.page = 1;
                this.articles = [];
                this.getPosts();
            }
        },
        articles (newval, oldval) {
            if (this.featured) {
                this.featuredArticle = this.articles[0];
            }
        }
    },
    created() {
        this.concept = this.initialConcept;
    }
  }
</script>
