<template>
    <div class="max-w-[1420px] m-auto mb-[80px]">
        <h3 class="text-center uppercase mb-[20px] tracking-[3.5px] text-[22px]">Top Cities</h3>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-5 px-5">
            <a
                v-for="city in top_cities"
                :key="city.slug"
                class="relative rounded-lg overflow-hidden max-w-[420px]"
                :href="`cities/${city.slug}`"
            >
                <img
                    :src="city.thumbnail.preview_full"
                    alt=""
                    class="w-full h-full object-cover"
                >
                <div
                    v-if="city.shorthand"
                    class="
                        absolute
                        top-[50%]
                        left-[50%]
                        translate-x-[-50%]
                        translate-y-[-50%]
                        text-white
                        font-title
                        text-[28px]
                        font-bold
                        py-1
                        px-5
                        bg-black
                        rounded-lg
                    "
                >
                    {{ city.shorthand  }}
                </div>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        top_cities: {
            type: Array,
            required: true
        }
    }
}
</script>