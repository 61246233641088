<template>
    <section class="w-full">
        <div class="w-full max-w-[1340px] m-auto grid grid-cols-12 sm:mt-5 gap-5 sm:p-5 md:p-0 mb-10">
            <div class="col-span-12 md:col-span-4 lg:col-span-3 flex flex-col">
                <div class="select-arrows w-full text-base">
                    <select
                        v-model="activeConcept"
                        name="sector"
                        id=""
                        class="
                            font-title
                            front-bold
                            bg-brown-100
                            rounded-lg
                            p-2.5
                        "
                    >
                        <option :value=null>All Categories</option>
                        <option
                            v-for="concept in concepts"
                            :key="concept.id"
                            :value="concept.slug"
                        >
                        {{ concept.name }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="col-span-12 hidden md:col-span-2 lg:col-span-5 sm:flex flex-col"></div>

            <div class="col-span-9 md:col-span-4 lg:col-span-3">
                <div class="text-base search-club overflow-hidden">
                    <input
                        type="text"
                        class="w-full font-title front-bold bg-brown-100 rounded-lg p-2.5 h-[55px]"
                        name=""
                        :value="searchArticleKeyword"
                        @input="handleInputArticle($event)"
                        @keypress="handleInputArticle($event)"
                        placeholder="Enter city or keyword"
                    />

                    <button
                        v-if="searchArticleKeyword"
                        @click="reset()"
                        class="
                            absolute right-0
                            h-[55px]
                            p-2.5
                            uppercase font-title"
                        >
                        X
                    </button>
                </div>
            </div>
            <div class="col-span-3 md:col-span-2 lg:col-span-1">
                <div class="bg-brown-100 w-full flex flex-row justify-around rounded-lg overflow-hidden h-[55px]">
                    <span class="border-r border-brown-200 flex-grow">
                        <button
                            :disabled="!searchArticleKeyword"
                            @click.prevent="searchKeyword()"
                            class="
                                w-full
                                h-full
                                flex justify-center items-center
                                rounded-lg
                                p-2.5
                                bg-orange-500 disabled:opacity-75
                                uppercase text-[12px]"
                            >
                                Search
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        keyword: {
            type: String,
        },
        concept: {
            type: String,
            required: false,
        }
    },
    data() {
        return {
            activeConceptSlug: null,
            activeConcept: this.concept ? this.concept : null,
            concepts: [],
            searchArticleKeyword: this.keyword ? this.keyword : null,
        }
    },
    methods: {
        async getConcepts () {
            fetch("/api/articles/concepts", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => response.json())
            .then((response) => {
                for (const [key, concept] of Object.entries(response.data)) {
                    if (concept) {
                        this.concepts.push(concept)
                    }
                }
            })
            .catch((error) => {
                this.error = 'Error getting posts.';
            });
        },
        searchArticle (keyword) {
            this.$emit('update:keyword', keyword);
        },
        handleInputArticle(event) {
            // enter key was pressed!
            if (event.keyCode === 13) {
                this.searchKeyword()
            }
            this.searchArticleKeyword = event.target.value;
        },
        searchKeyword() {
            this.searchArticle(this.searchArticleKeyword);
        },
        reset() {
            this.searchArticleKeyword = null;
            window.location.reload();
        }
    },
    mounted() {
        this.getConcepts();
    },
    watch: {
        activeConcept() {
            this.$emit('update:concept', this.activeConcept);
        }
    }
}
</script>
